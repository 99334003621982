@use '@ag-grid-community/styles' as ag;

// @import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'metismenujs/scss/metismenujs';
// Global icon font
@import './assets/fonts/feather/style.css';

@import 'theme';

@import 'ngx-toastr/toastr';

.ag-floating-filter-full-body ng-component {
  width: 100%;
  height: 100%;
}

.invalid-feedback {
  display: block !important;
}

.section-title {
  font-size: 20px;
  color: $black;
  font-weight: 700;
  // margin-bottom: 1.5rem;
}
.action-panel {
  margin-bottom: 3rem !important;
}

.nav.nav-tabs {
  .nav-item {
    .nav-link {
      color: $secondary;
      font-weight: 500;

      &.active {
        color: black;
        font-weight: 700;
        border-bottom: 3px solid $primary;

        .material-icons-outlined {
          color: $primary;
        }
      }
    }
  }

  &.flex-grow-content {
    + .tab-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .tab-pane {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.action {
  font-size: 20px;
  cursor: pointer;
  margin-left: 0.5rem !important;
  color: rgba(var(--bs-primary-rgb), 1) !important;
}
label.input-field {
  font-weight: 700;
  font-size: 15px;
}
ul.pagination {
  margin-bottom: 0;
}

.content-panel {
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 0 7px 4px rgba(var(--bs-black-rgb), 0.02);
  padding: 20px;
  // margin-bottom: 3rem !important;

  &.content-panel-list {
    height: 720px;
    display: flex;
    flex-direction: column;
  }
  &.content-panel-tab {
    height: 700px;
    display: flex;
    flex-direction: column;
    .tab-header-container {
      display: flex;
      .tab-header {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
      }
    }
    .tab-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  .section-title {
    margin-bottom: 1.5rem;
  }

  .title,
  .tab-title {
    font-size: 16px;
    color: $black;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .tab-title {
    margin-right: 2px;
    border-bottom: 4px solid var(--bs-gray-200);
    &.active {
      border-bottom: 4px solid $primary;
    }
  }
}
ag-grid-angular {
  // min-height: 500px;
  // height: 500px;
  flex-grow: 1;
}

@include ag.grid-styles(
  (
    theme: heitown,
    extend-theme: quartz,
    row-height: 30px,
    active-color: $primary,
    row-border-width: 0,
    background-color: white,
    font-family: (
      'DM Sans',
      sans-serif,
    ),
    header-column-resize-handle-height: 100%,
    header-column-resize-handle-width: 1px,
    // font-size: $font-size-base,
    odd-row-background-color: rgba($primary, 0.06),
    // data-color: $dark,
    border-color: $gray-200,
    header-background-color: $gray-100,
    header-foreground-color: $secondary,
    border-radius: 8px,
  )
);

// .ag-theme-alpine {
// @include ag-theme-alpine(
//   (
//     font-family: (
//       'DM Sans',
//       sans-serif,
//     ),
//     row-height: 30px,
//     alpine-active-color: $primary,
//     cell-horizontal-padding: 8px,
//     borders: true,
//     borders-secondary: true,
//     border-color: $gray-200,
//     header-column-resize-handle-color: $gray-400,
//     border-radius: 8px,
//     row-border-color: null,
//     background-color: #fff,
//     control-panel-background-color: #fff,
//     header-background-color: $gray-100,
//     header-foreground-color: $secondary,
//   )
// );
.ag-root-wrapper {
  // @include ag-border(primary, $none: true);
}
.ag-header-row {
  font-weight: 700;
  text-transform: uppercase;
  // color: var(--bs-secondary);
  // @include ag-color-property(
  //   var(--bs-gray-700),
  //   var(--ag-header-foreground-color)
  // );
}
.ag-row {
  color: $black;
  font-weight: 500;
}

.ag-cell {
  line-height: 28px;
}

.ag-header-cell-menu-button {
  // display: none !important;
}

.ag-floating-filter-full-body {
  overflow: visible;
}
// }

.dropdown-toggle::after {
  margin-left: -0.1em !important;
}
.head-section {
  display: flex;
  .btn-add {
    margin-left: auto !important;
    display: flex;
    align-items: center;
    .icon-btn {
      margin: 0 0.25rem 0 0 !important;
    }
  }
}

.filters-container {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.rapid-filters-container {
  display: flex;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.rapid-filter {
  font-weight: 700;
  margin-right: 0.5rem !important;
}

.icon-btn {
  font-size: 20px;
  cursor: pointer;
  margin: 0.5rem;
}
.form-container {
  margin-top: 1rem !important;
  //margin-bottom: 3rem !important;
}
.heitown-form-field {
  margin-bottom: 1rem !important;
}
.action-button {
  margin-left: 0.5rem !important;
}
.action-container,
.action-button-container {
  text-align: right !important;
}
.dropdown-menu {
  z-index: 1056 !important;
}

.popover {
  background-color: white !important;
}
